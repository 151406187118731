import { Button, Icon } from "@grow-therapy-team/sprout-ui";
import classNames from "classnames";
import { ReactNode, useEffect, useRef, useState } from "react";

export enum ScrollDirection {
  UP = "up",
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right",
}

export function ScrollableGallery({ children }: { children: ReactNode[] }) {
  const [showUpArrow, setShowUpArrow] = useState(false);
  const [showDownArrow, setShowDownArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const galleryRef = useRef<HTMLDivElement>(null);
  const verticalScrollAmount =
    (galleryRef?.current?.clientHeight ?? 0) / children.length;
  const horizontalScrollAmount =
    (galleryRef?.current?.clientWidth ?? 0) / children.length;
  useEffect(
    function initializeScrollHandlers() {
      const handleHideAndShowScrollArrows = () => {
        const gallery = galleryRef.current;
        if (gallery) {
          setShowDownArrow(
            gallery.scrollTop <
              gallery.scrollHeight - (gallery.clientHeight + 1),
          );
          setShowUpArrow(gallery.scrollTop > 0);
          setShowRightArrow(
            gallery.scrollLeft <
              gallery.scrollWidth - (gallery.clientWidth + 1),
          );
          setShowLeftArrow(gallery.scrollLeft > 0);
        }
      };

      handleHideAndShowScrollArrows(); // Initial check
      const gallery = galleryRef.current;
      if (gallery) {
        gallery.addEventListener("scroll", handleHideAndShowScrollArrows);
        window.addEventListener("resize", handleHideAndShowScrollArrows);
      }

      return () => {
        if (gallery) {
          gallery.removeEventListener("scroll", handleHideAndShowScrollArrows);
          window.removeEventListener("resize", handleHideAndShowScrollArrows);
        }
      };
    },
    [galleryRef, children.length],
  );

  const handleArrowClick = (direction: ScrollDirection) => {
    const gallery = galleryRef.current;
    if (gallery) {
      switch (direction) {
        case ScrollDirection.UP:
          gallery.scroll({
            top: gallery.scrollTop - verticalScrollAmount,
            behavior: "smooth",
          });
          break;
        case ScrollDirection.DOWN:
          gallery.scroll({
            top: gallery.scrollTop + verticalScrollAmount,
            behavior: "smooth",
          });
          break;
        case ScrollDirection.LEFT:
          gallery.scroll({
            left: gallery.scrollLeft - horizontalScrollAmount,
            behavior: "smooth",
          });
          break;
        case ScrollDirection.RIGHT:
          gallery.scroll({
            left: gallery.scrollLeft + horizontalScrollAmount,
            behavior: "smooth",
          });
          break;
      }
    }
  };
  return (
    <div
      className={classNames(
        "flex sm:flex-col sm:h-full max-sm:w-full max-sm:max-w-full",
        {
          "sm:justify-center": !showUpArrow && !showDownArrow,
        },
      )}
    >
      <Button
        onClick={() => handleArrowClick(ScrollDirection.UP)}
        className={classNames(
          "hidden sm:flex items-center justify-center w-full pb-2 pt-0 transition-[height] transition-[padding] disabled:opacity-0",
          {
            "h-0 p-0": !showUpArrow,
          },
        )}
        aria-label={"Scroll up"}
        use="unstyled"
        disabled={!showUpArrow}
      >
        <Icon
          name="angle-up"
          className={classNames("text-neutral-100 transition-all", {
            "h-0": !showUpArrow,
          })}
        />
      </Button>

      <Button
        onClick={() => handleArrowClick(ScrollDirection.LEFT)}
        className={classNames(
          "sm:hidden flex items-center justify-center pr-2 pl-0 transition-[width] transition-[padding] disabled:opacity-0",
          {
            "w-0 p-0": !showLeftArrow,
          },
        )}
        aria-label={"Scroll left"}
        disabled={!showLeftArrow}
        use="unstyled"
      >
        <Icon
          name="angle-left"
          className={classNames("text-neutral-100", {
            "w-0 h-0 opacity-0 pointer-events-none": !showLeftArrow,
          })}
        />
      </Button>

      <div
        className={classNames(
          "flex sm:flex-col overflow-x-auto sm:overflow-y-auto sm:h-full sm:max-h-full scroll-smooth transition-all",
          {
            "sm:justify-center": !showUpArrow && !showDownArrow,
          },
        )}
        ref={galleryRef}
      >
        {children}
      </div>

      <Button
        onClick={() => handleArrowClick(ScrollDirection.DOWN)}
        className={classNames(
          "hidden sm:flex items-center justify-center w-full pt-2 pb-0 ml-0 transition-[height] transition-[padding] disabled:opacity-0",
          {
            "h-0 p-0": !showDownArrow,
          },
        )}
        aria-label={"Scroll down"}
        disabled={!showDownArrow}
        use="unstyled"
      >
        <Icon
          name="angle-down"
          className={classNames("text-neutral-100 transition-all", {
            "h-0": !showDownArrow,
          })}
        />
      </Button>
      <Button
        onClick={() => handleArrowClick(ScrollDirection.RIGHT)}
        className={classNames(
          "sm:hidden flex items-center justify-center pl-2 pr-0 transition-[width] transition-[padding] disabled:opacity-0",
          {
            "w-0 p-0": !showRightArrow,
          },
        )}
        aria-label={"Scroll right"}
        disabled={!showRightArrow}
        use="unstyled"
      >
        <Icon
          name="angle-right"
          className={classNames("text-neutral-100", {
            "w-0": !showRightArrow,
          })}
        />
      </Button>
    </div>
  );
}

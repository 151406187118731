import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  CannedMessages,
  CannedMessagesProps,
  ChatDrawer as BaseChatDrawer,
  ChatDrawerProps as BaseChatDrawerProps,
} from "../../components";
import {
  DrawerState,
  drawerStateAtom,
  numWaitingVisitorsAtom,
  sessionConversationReadyAtom,
} from "../state";
import {
  twilioIdentityAtom,
  currentConversationAtom,
  currentConversationMessagesAtom,
  currentConversationParticipantNamesAtom,
  connectedAtAtom,
  currentConversationSidAtom,
  isChatErrorAtom,
} from "../../twilio/state";
import { Text, IconBadge, IconBadgeUse } from "@grow-therapy-team/sprout-ui";

import { TrackingEvents, sendLoggingEvents } from "../../events";
import { CANNED_MESSAGES, NO_PARTICIPANTS_MESSAGE } from "./constants";
import { ParticipantStatus } from "../../types";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";

function QuickReply(props: Omit<CannedMessagesProps, "messages">) {
  return <CannedMessages messages={CANNED_MESSAGES} {...props} />;
}

function SessionChatPlaceholder() {
  return (
    <div className="flex flex-col gap-6 items-center h-full">
      <IconBadge
        size="xl"
        icon={faMessage}
        use={IconBadgeUse.Lilac}
        className="text-[1.25rem]"
      />
      <section>
        {/* FIXME: Sprout Text as h2 seemed to conflict with text-xlarge */}
        <h2 className="text-xlarge font-semibold rebrand:font-medium text-center mb-2">
          Messaging
        </h2>
        <Text className="text-neutral-600 self-center text-center">
          Chat with clients here once they enter your waiting room.
        </Text>
      </section>
    </div>
  );
}

export function ChatDrawer({
  drawerState,
  isEmptyState,
  isError,
  onSend,
  numberOfClientChats,
  onGoToChatList,
  ...otherProps
}: { drawerState?: DrawerState | null } & BaseChatDrawerProps & {
    numberOfClientChats: number;
  }) {
  const isWaitingRoomChat = drawerState === DrawerState.WAITING_ROOM_CHAT;
  const isSessionChat = drawerState === DrawerState.SESSION_CHAT;
  const isParticipantDisconnectedChat =
    drawerState === DrawerState.PARTICIPANT_DISCONNECTED_CHAT;
  const chatStatus = isWaitingRoomChat
    ? ParticipantStatus.WAITING
    : ParticipantStatus.IN_SESSION;

  const onQuickReplySelect = (message: string) => {
    sendLoggingEvents(TrackingEvents.PROVIDER_TEMPLATE_MESSAGE_SEND);
    onSend(message);
  };

  const onMessageSend = (message: string) => {
    sendLoggingEvents(TrackingEvents.PROVIDER_MESSAGE_SEND);
    onSend(message);
  };

  const hasOtherAvailableChats =
    numberOfClientChats > 1 ||
    (isParticipantDisconnectedChat && numberOfClientChats > 0);

  return (
    <BaseChatDrawer
      {...(hasOtherAvailableChats && { onGoToChatList })}
      {...(!isEmptyState && !isParticipantDisconnectedChat && { chatStatus })}
      headerText={
        isSessionChat && !isEmptyState
          ? "Messages will persist only for the duration of the session"
          : undefined
      }
      emptyState={<SessionChatPlaceholder />}
      isEmptyState={isEmptyState}
      isError={isError}
      quickReplyComponent={
        isWaitingRoomChat ? (
          <QuickReply disabled={isError} onSelect={onQuickReplySelect} />
        ) : undefined
      }
      sendIsDisabled={isParticipantDisconnectedChat}
      onSend={onMessageSend}
      footerText={
        isParticipantDisconnectedChat ? NO_PARTICIPANTS_MESSAGE : undefined
      }
      {...otherProps}
    />
  );
}

export function ChatDrawerWrapper() {
  const [drawerState, setDrawerState] = useAtom(drawerStateAtom);
  const numWaitingVisitors = useAtomValue(numWaitingVisitorsAtom);
  const sessionConversationReady = useAtomValue(sessionConversationReadyAtom);
  const numberOfClientChats = sessionConversationReady
    ? numWaitingVisitors + 1
    : numWaitingVisitors;
  const connectedAt = useAtomValue(connectedAtAtom);
  const conversation = useAtomValue(currentConversationAtom);
  const messages = useAtomValue(currentConversationMessagesAtom);
  const identity = useAtomValue(twilioIdentityAtom);
  const chatName = useAtomValue(currentConversationParticipantNamesAtom).join(
    ", ",
  );
  const isChatError = useAtomValue(isChatErrorAtom);
  const setCurrentConversationSid = useSetAtom(currentConversationSidAtom);
  const sendMessage = (message: string) => {
    sendLoggingEvents(TrackingEvents.ANY_MESSAGE_SEND);
    if (drawerState === DrawerState.WAITING_ROOM_CHAT) {
      sendLoggingEvents(TrackingEvents.ANY_WAITING_ROOM_MESSAGE_SEND);
    }
    if (drawerState === DrawerState.SESSION_CHAT) {
      sendLoggingEvents(TrackingEvents.ANY_SESSION_MESSAGE_SEND);
    }
    conversation?.sendMessage(message);
  };

  const onGoToChatList = () => {
    setDrawerState(DrawerState.ALL_CLIENTS_CHAT_LIST);
    setCurrentConversationSid(undefined);
  };

  return (
    <ChatDrawer
      onGoToChatList={onGoToChatList}
      onSend={sendMessage}
      isError={isChatError}
      isEmptyState={
        numWaitingVisitors === 0 &&
        drawerState === DrawerState.WAITING_ROOM_CHAT
      }
      {...{
        chatName,
        connectedAt,
        drawerState,
        identity,
        messages,
        numberOfClientChats,
      }}
    />
  );
}

import { gql, useQuery } from "@apollo/client";

import { ConsentStatus } from "../../twilio/types";
import {
  inSessionPatientInformationAtom,
  providerTranscriptionConsentAtom,
} from "../state";
import { useSetAtom } from "jotai";

export type ProviderPatientConsentVariable = {
  providerShortId?: string;
  patientShortId?: string;
};
export type ProviderPatientConsentData = {
  clientTranscriptionConsent?: {
    isOptedIn: boolean;
  };
  providerTranscriptionConsent?: {
    isOptedIn: boolean;
  };
};
export const GET_PROVIDER_PATIENT_CONSENT = gql`
  query GetProviderPatientTranscriptionConsent(
    $patientShortId: String!
    $providerShortId: String!
  ) {
    clientTranscriptionConsent(patientShortId: $patientShortId) {
      isOptedIn
    }
    providerTranscriptionConsent(providerShortId: $providerShortId) {
      isOptedIn
    }
  }
`;

export function optedInToConsentStatus(optedIn?: boolean): ConsentStatus {
  if (optedIn === undefined) return ConsentStatus.PENDING;

  return optedIn ? ConsentStatus.OPTED_IN : ConsentStatus.OPTED_OUT;
}

export function useGetProviderPatientConsent(
  providerShortId?: string,
  patientShortId?: string,
) {
  const setInSessionPatientInfo = useSetAtom(inSessionPatientInformationAtom);
  const setProviderTranscriptionConsent = useSetAtom(
    providerTranscriptionConsentAtom,
  );
  const consents = useQuery<
    ProviderPatientConsentData,
    ProviderPatientConsentVariable
  >(GET_PROVIDER_PATIENT_CONSENT, {
    variables: { providerShortId, patientShortId },
    skip: !providerShortId || !patientShortId,
    context: { skipAuth: false },
    onCompleted: (data) => {
      setInSessionPatientInfo((prev) => {
        if (prev === undefined) return prev;
        return {
          ...prev,
          clientTranscriptionConsent:
            !!data.clientTranscriptionConsent?.isOptedIn,
        };
      });
      setProviderTranscriptionConsent(
        !!data.providerTranscriptionConsent?.isOptedIn,
      );
    },
  });
  const providerOptIn = consents.data?.providerTranscriptionConsent?.isOptedIn;
  const patientOptIn = consents.data?.clientTranscriptionConsent?.isOptedIn;

  const providerConsent = optedInToConsentStatus(providerOptIn);
  const patientConsent = optedInToConsentStatus(patientOptIn);

  return {
    providerConsent,
    patientConsent,
  };
}

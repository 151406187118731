import { localDataTrackAtom } from "../state";
import { providerShortIdAtom } from "../../provider/state";
import { visitorAtom } from "../../visitor/state";
import { DataTrackMessageTypeEnum, DataTrackMessageDetails } from "../types";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { useCallback } from "react";
import { UserType } from "../../types";
import { useAtomCallback } from "jotai/utils";

export const useSendDataTrackMessageCallback = () => {
  const getDataTrack = useAtomCallback(
    useCallback((get) => get(localDataTrackAtom), []),
  );

  const getProviderShortId = useAtomCallback(
    useCallback((get) => get(providerShortIdAtom), []),
  );
  const getVisitor = useAtomCallback(
    useCallback((get) => get(visitorAtom), []),
  );

  return useCallback(
    (
      messageType: DataTrackMessageTypeEnum,
      details?: DataTrackMessageDetails,
    ) => {
      const providerShortId = getProviderShortId();
      const { visitorUuid } = getVisitor();
      const sender = {
        entityId: providerShortId ? providerShortId : visitorUuid,
        entityType: providerShortId ? UserType.PROVIDER : UserType.CLIENT,
      };

      const formattedMessage = {
        messageType,
        details,
        sender,
      };

      const localDataTrack = getDataTrack().track;
      if (localDataTrack) {
        localDataTrack.send(JSON.stringify(formattedMessage));
        sendLoggingEvents(
          TrackingEvents.REMOTE_DATA_TRACK_MESSAGE_SENT,
          { messageType: formattedMessage?.messageType },
          {
            logLevel: "info",
            message: "Local participant sent a message on the data track",
          },
        );
      }
    },
    [getDataTrack, getProviderShortId, getVisitor],
  );
};

import {
  Heading,
  Tag,
  Button,
  Card,
  DescriptionList,
  DescriptionListItem,
} from "@grow-therapy-team/sprout-ui";

import { useState } from "react";
import { AppointmentType } from "../schedule-preview/types";
import {
  getAppointmentTagColor,
  getAppointmentTypeText,
} from "../schedule-preview/utils";

type ClientInformationSectionProps = {
  clientInformation: {
    appointmentType: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    preferredShortName: string | null;
    primaryInsuranceCoverage: string | null;
    preferredFullName: string | null;
    pronouns: string | null;
    gender: string | null;
    readableDob: string | null;
    formattedAddress: string | null;
    isSelfPay: boolean;
    telephone: string | null;
  };
};

export function ClientInformationSection({
  clientInformation,
}: ClientInformationSectionProps) {
  const {
    appointmentType,
    firstName,
    lastName,
    email,
    preferredFullName,
    primaryInsuranceCoverage,
    pronouns,
    gender,
    readableDob,
    formattedAddress,
    isSelfPay,
  } = clientInformation;
  const [isClientInformationExpanded, setIsClientInformationExpanded] =
    useState(false);
  const legalName = firstName && lastName ? `${firstName} ${lastName}` : "";
  const appointmentIsConsultation =
    appointmentType === AppointmentType.Consultation;

  const defaultClientInformation = [
    { label: "Legal First And Last Name", value: legalName },
    { label: "Chosen Name", value: preferredFullName },
    { label: "Pronouns", value: pronouns },
    { label: "Birthdate", value: readableDob },
  ];

  const expandedClientInformation = [
    { label: "Email", value: email },
    {
      label: "Address",
      value: appointmentIsConsultation ? undefined : formattedAddress,
    },
    {
      label: "Phone Number",
      value: appointmentIsConsultation
        ? clientInformation.telephone
        : undefined,
    },
    { label: "Sex On Insurance", value: gender },
    {
      label: "Payment Option",
      value: isSelfPay ? "Cash" : primaryInsuranceCoverage,
    },
  ];

  return (
    <div
      className="flex flex-col gap-6"
      data-testid="client-information-section.body.content"
    >
      <Heading variant="md">Client information</Heading>
      <Card>
        <DescriptionList>
          {defaultClientInformation.map((item, i) => {
            if (!item.value) return null;
            return (
              <span
                className="fs-exclude"
                data-testid={`client-information-section.list-item-${i}`}
                data-dd-privacy="mask"
                data-dd-action-name="Client information section"
                key={item.label}
              >
                <DescriptionListItem label={item.label}>
                  {item.label === "Appointment Type" ? (
                    <Tag
                      use={getAppointmentTagColor(
                        item.value as AppointmentType,
                      )}
                    >
                      {getAppointmentTypeText(item.value as AppointmentType)}
                    </Tag>
                  ) : (
                    item.value
                  )}
                </DescriptionListItem>
              </span>
            );
          })}
          {isClientInformationExpanded && (
            <DescriptionList>
              {expandedClientInformation.map((item, i) => {
                if (!item.value) return null;
                return (
                  <span
                    data-testid={`client-information-section.expanded-list-item-${i}`}
                    data-dd-privacy="mask"
                    data-dd-action-name="Click client information"
                    className="fs-exclude"
                    key={item.label}
                  >
                    <DescriptionListItem label={item.label}>
                      {item.value}
                    </DescriptionListItem>
                  </span>
                );
              })}
            </DescriptionList>
          )}
        </DescriptionList>
        <Button
          data-testid="client-information-section.expand"
          use="link"
          className="p-0"
          onClick={() =>
            setIsClientInformationExpanded(!isClientInformationExpanded)
          }
        >
          {isClientInformationExpanded
            ? "Show less information"
            : "Show all information"}
        </Button>
      </Card>
    </div>
  );
}

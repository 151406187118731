import {
  Card,
  IconBadge,
  IconBadgeUse,
  Icon,
  Skeleton,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { Appointments } from "./types";
import { useGetSchedulePreviewForToday } from "../useGetScheduleForToday";
import { ErrorBoundary } from "react-error-boundary";
import { SchedulePreviewAppointmentWrapper as SchedulePreviewAppointment } from "./SchedulePreviewAppointment";
import {
  EMPTY_CARD_MESSAGE,
  ERROR_CARD_MESSAGE,
  UPCOMING_TODAY_HEADER,
} from "./constants";
import { useEffect } from "react";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import { faMugHot, faWarning } from "@fortawesome/pro-solid-svg-icons";

const NUM_SKELETON_TILES = 2;
const cardStyles = "mt-0 rebrand:[&>div]:mb-[1.5rem] w-[unset]";

const LoadingCard = () => (
  <Card title={UPCOMING_TODAY_HEADER} className={cardStyles}>
    <div className="flex flex-col space-y-5">
      {[...Array(NUM_SKELETON_TILES)].map((_, i) => (
        <Card
          className="flex flex-col sm:!p-6 sm:min-w-[25rem] border-neutral-600"
          data-testid="loading-appointments-card"
          key={i}
        >
          <Skeleton className="mx-0 my-0 w-32 h-4" />
          <div className="mt-1 flex items-center justify-between">
            <Skeleton className="mx-0 my-0 w-40 sm:w-60 mr-3" />
            <Skeleton className="mx-0 my-0 w-20" />
          </div>
        </Card>
      ))}
    </div>
  </Card>
);

const ErrorCard = () => (
  <Card title={UPCOMING_TODAY_HEADER} className={cardStyles}>
    <Card className="flex sm:!p-6 sm:min-w-[25rem]">
      <Icon icon={faWarning} className="mr-2" color="#CC6F52" />
      <Text variant="xs" className="text-left fs-exclude">
        {ERROR_CARD_MESSAGE}
      </Text>
    </Card>
  </Card>
);

const EmptyCard = () => (
  <Card className="sm:min-w-[30rem] w-[unset]">
    <IconBadge
      size="xl"
      icon={faMugHot}
      use={IconBadgeUse.Green}
      className="mx-auto"
    />
    <Text variant="sm" className="text-neutral-700 mt-6 text-center">
      {EMPTY_CARD_MESSAGE}
    </Text>
  </Card>
);

export function SchedulePreview({
  appointments,
  isError,
  isLoading,
}: {
  appointments?: Appointments;
  isError?: boolean;
  isLoading?: boolean;
}) {
  if (isLoading) {
    return <LoadingCard />;
  }

  if (isError) {
    return <ErrorCard />;
  }

  if (!appointments?.length) {
    return <EmptyCard />;
  }

  const sortedAppointments = [...appointments].sort(
    // @ts-ignore appointments length is checked above so we know it's not null
    ({ node: a }, { node: b }) =>
      new Date(a?.timeStart).getTime() - new Date(b?.timeStart).getTime(),
  );
  return (
    <Card title={UPCOMING_TODAY_HEADER} className={cardStyles}>
      <div className="flex flex-col space-y-5">
        {sortedAppointments?.map((appointment, index) => (
          <div
            key={appointment?.node?.appointmentShortId}
            data-testid="schedule-preview-appointment"
          >
            <SchedulePreviewAppointment
              appointment={appointment?.node ?? null}
              appointmentPosition={index + 1}
            />
          </div>
        ))}
      </div>
    </Card>
  );
}

export function SchedulePreviewWrapper() {
  const {
    loading: isLoading,
    error,
    data: appointments,
  } = useGetSchedulePreviewForToday();
  const { userViewed } = useTrackEvent();
  useEffect(() => {
    if (appointments || error) {
      userViewed(EventTypeEnum.WAITING_ROOM_SCHEDULE, {
        numberOfAppointments: appointments?.allAppointments?.edges?.length ?? 0,
        scheduleError: !!error,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments, error]);
  return (
    <ErrorBoundary FallbackComponent={() => <ErrorCard />}>
      <SchedulePreview
        isLoading={isLoading}
        appointments={appointments?.allAppointments?.edges}
        isError={!!error}
      />
    </ErrorBoundary>
  );
}

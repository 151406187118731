import { useGate } from "statsig-react";
import {
  RemoteParticipantWrapper as BaseRemoteParticipant,
  isRecordingAtom,
  RemoteParticipantWrapperProps,
} from "../twilio";
import { useHandleReceivedMessageCallback } from "./messages/useHandleReceivedMessageCallback";
import { useSendClientTranscriptionConsents } from "./session-recording/useSendClientTranscriptionConsents";
import { telehealthTranscriptionGate } from "../statsig/gates";
import { useRecordingControl } from "../twilio/audio-video-controls/useRecordingControl";
import { useAtomValue } from "jotai";
import { inSessionPatientInformationAtom, providerShortIdAtom } from "./state";
import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";

export function RemoteParticipant(
  props: Omit<RemoteParticipantWrapperProps, "onMessageReceived">,
) {
  const handleReceivedMessage = useHandleReceivedMessageCallback();
  const { value: telehealthTranscriptionIsEnabled } = useGate(
    telehealthTranscriptionGate,
  );
  const sendConsentStatuses = useSendClientTranscriptionConsents(
    telehealthTranscriptionIsEnabled,
  );
  const { patientShortId } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};
  const providerShortId = useAtomValue(providerShortIdAtom);
  const { sendRecordingStatusUpdate } = useRecordingControl(
    providerShortId,
    patientShortId,
    telehealthTranscriptionIsEnabled,
  );
  const getRecordingStatus = useAtomCallback(
    useCallback((get) => get(isRecordingAtom), []),
  );

  const onDataTrackSubscribed = useCallback(() => {
    sendConsentStatuses();
    sendRecordingStatusUpdate(getRecordingStatus());
  }, [getRecordingStatus, sendConsentStatuses, sendRecordingStatusUpdate]);

  return (
    <BaseRemoteParticipant
      {...props}
      onMessageReceived={handleReceivedMessage}
      onDataTrackSubscribed={onDataTrackSubscribed}
    />
  );
}

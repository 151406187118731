import { faPhoneHangup } from "@fortawesome/pro-solid-svg-icons";
import { Button, ButtonProps, IconButton } from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";

export function EndSessionButton({
  children,
  className,
  ...props
}: ButtonProps & {
  "aria-label": string;
}) {
  return (
    <>
      <IconButton
        className="flex sm:hidden bg-ruby-500 text-neutral-000 shadow-none hover:bg-[#a0121a]"
        use="destructive"
        iconDefinition={faPhoneHangup}
        {...props}
      />
      <Button
        {...props}
        className={twMerge(
          "hidden sm:flex bg-ruby-500 text-neutral-000 shadow-none hover:bg-[#a0121a]",
          className,
        )}
        use="destructive"
      >
        {children}
      </Button>
    </>
  );
}

import { gql, useQuery } from "@apollo/client";
import { Server } from "../../apollo/types";
import type { MICTypes } from "@grow-therapy-team/sprout-ui";
import { useAtomValue } from "jotai";
import { providerShortIdAtom, selectedClientUserShortIdAtom } from "../state";

export const GET_CLIENT_MEASURE_BUNDLES_QUERY = gql`
  query GetClientMeasureBundles($clientUserShortId: ID!, $providerShortId: ID) {
    clientSentMeasureBundles(
      clientUserShortId: $clientUserShortId
      providerShortId: $providerShortId
    ) {
      measureBundleShortId
      createdAt
      sentAt
      completedAt
      timeClose
      additionalDetails
      measureBundleItems {
        measureBundleItemId
        measureResponse {
          measureResponseShortId
          score
          scoreCategoryLabel
          completedAt
          responseContent
        }
        measureSchema {
          measureSchemaShortId
          isProviderSpecific
          type
          sections {
            key
            visibleConditionName
            fields {
              key
              visibleConditionName
              options {
                score
                key
              }
            }
          }
        }
      }
    }
  }
`;

export type TClientMeasureBundles = {
  clientSentMeasureBundles: MICTypes.MeasureBundle[];
};

export function useGetClientMeasureBundles() {
  const providerShortId = useAtomValue(providerShortIdAtom);
  const clientUserShortId = useAtomValue(selectedClientUserShortIdAtom);

  return useQuery<TClientMeasureBundles>(GET_CLIENT_MEASURE_BUNDLES_QUERY, {
    skip: !clientUserShortId,
    context: { server: Server.MONOLITH },
    variables: { clientUserShortId, providerShortId },
  });
}

import { useAtomValue } from "jotai";
import { isRecordingAtom, participantCountAtom } from "../state";
import { useSendDataTrackMessageCallback } from "../messages/useSendDataTrackMessageCallback";
import { DataTrackMessageTypeEnum } from "../types";
import { useGetTelehealthSessionInfo } from "../../hooks/useGetTelehealthSessionInfo";
import { useCallback } from "react";

export function useRecordingControl(
  providerShortId?: string,
  patientShortId?: string,
  featureEnabled: boolean = false,
) {
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const appointmentShortId = data?.telehealthSessionInfo?.appointment?.shortId;
  const isRecording = useAtomValue(isRecordingAtom);
  const sendMessage = useSendDataTrackMessageCallback();
  const inSession = !!useAtomValue(participantCountAtom);
  const sendRecordingStatusUpdate = useCallback(
    async (shouldRecord: boolean) => {
      sendMessage(DataTrackMessageTypeEnum.RECORDING_STATUS_UPDATED, {
        isRecording: shouldRecord,
      });
    },
    [sendMessage],
  );
  const isVisible = inSession && appointmentShortId && featureEnabled;

  return {
    isVisible,
    isRecording,
    sendRecordingStatusUpdate,
  };
}

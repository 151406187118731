import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { Toast, ToastProps, ToastVariant } from "../../components";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";

const ConsentDeclinedToastText = {
  VISITOR_DECLINED_CONSENT:
    "We've let your provider know and saved your consent setting.",
  OTHER_VISITOR_DECLINED_CONSENT:
    "Another participant has declined to consent to transcription. The session will not be recorded.",
};

interface ConsentDeclinedToastProps extends Omit<ToastProps, "children"> {
  visitorDeclinedConsent: boolean;
}

export function ConsentDeclinedToast({
  visitorDeclinedConsent,
  ...rest
}: ConsentDeclinedToastProps) {
  return (
    <Toast
      className="bottom-center-toast sm:max-w-fit"
      variant={ToastVariant.Neutral}
      {...rest}
    >
      <div className="flex flex-row gap-2 items-center">
        <Icon icon={faCircleCheck} />
        <Text variant="sm">
          {visitorDeclinedConsent
            ? ConsentDeclinedToastText.VISITOR_DECLINED_CONSENT
            : ConsentDeclinedToastText.OTHER_VISITOR_DECLINED_CONSENT}
        </Text>
      </div>
    </Toast>
  );
}

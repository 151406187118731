import { MICMeasure } from "@grow-therapy-team/sprout-ui";
import { PageComponentProps } from "..";
import { MeasureSchemaAndAnswers } from "./types";

export function MeasuresContent({
  page,
  setPage,
  children,
}: PageComponentProps<MeasureSchemaAndAnswers> & {
  children?: React.ReactNode;
}) {
  if (!page.schema) return;

  return (
    <div className="flex flex-col gap-y-3">
      <MICMeasure
        onChange={({ key, value }) => {
          setPage((prev) => ({
            ...prev,
            answers: { ...page.answers, [key]: parseInt(value) },
            errors: prev.errors.filter((error) => error !== key),
          }));
        }}
        schema={page.schema}
        values={page.answers}
        errors={page.errors}
      />
      {children}
    </div>
  );
}

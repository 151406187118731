import {
  Icon,
  Menu,
  MenuItem,
  MenuToggleButton,
  Tag,
  TagUse,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { VisualIndicator } from "./VisualIndicator";
import { useTheme } from "../hooks";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { Theme } from "../state";
import {
  faGear,
  faMemo,
  faMessage,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";

export function AdditionalOptionsMenu({
  onClickSettingsButton,
  onClickChatButton,
  unreadMessageCount,
  isChatButtonDisabled,
  onClickClientInformationButton,
  onClickClientsButton,
  numberOfWaitingClients,
  hasNewClientInfo,
}: {
  onClickSettingsButton: () => void;
  onClickChatButton: () => void;
  unreadMessageCount: number;
  isChatButtonDisabled?: boolean;
  onClickClientInformationButton?: () => void;
  onClickClientsButton?: () => void;
  hasNewClientInfo?: boolean;
  numberOfWaitingClients?: number;
}) {
  const { theme } = useTheme();
  const shouldShowVisualIndicator =
    !!unreadMessageCount || hasNewClientInfo || !!numberOfWaitingClients;
  return (
    <Menu
      placement="bottom-left"
      menuClassName="translate-x-[calc(50%-1.5rem)] -translate-y-[calc(100%+3.875rem)] sm:[hidden] rebrand:border-neutral-800 rebrand:border-[1px] mt-0"
      buttonClassName={twMerge(
        classNames(
          {
            "rebrand:bg-neutral_rebrand-900 rebrand:text-neutral-100 rebrand:hover:bg-[#000000]":
              theme === Theme.DARK,
          },
          "p-4 sm:hidden",
        ),
      )}
      toggleButton={
        <MenuToggleButton
          visibleCaret={false}
          use="secondary"
          aria-label="View additional options"
        >
          <Icon name="ellipsis" className="flex h-4 w-4" />
          {shouldShowVisualIndicator && (
            <VisualIndicator className="absolute -top-1 -right-1" />
          )}
        </MenuToggleButton>
      }
    >
      {onClickClientsButton ? (
        <MenuItem
          onClick={onClickClientsButton}
          aria-label="Open clients"
          className="py-3"
        >
          <Text className="flex flex-row items-center gap-2">
            <Icon icon={faUser} />
            Clients
            {!!numberOfWaitingClients && (
              <Tag use={TagUse.Lilac}>{numberOfWaitingClients}</Tag>
            )}
          </Text>
        </MenuItem>
      ) : (
        <></>
      )}
      <MenuItem
        className="flex gap-2 py-3"
        onClick={onClickChatButton}
        disabled={isChatButtonDisabled}
        aria-label={`Open chat, ${unreadMessageCount} unread ${
          unreadMessageCount === 1 ? "message" : "messages"
        }`}
      >
        <Text className="flex flex-row items-center gap-2">
          <Icon icon={faMessage} />
          Chat
          {!!unreadMessageCount && (
            <Tag use={TagUse.Lilac}>{unreadMessageCount}</Tag>
          )}
        </Text>
      </MenuItem>
      <MenuItem
        onClick={onClickSettingsButton}
        aria-label="Open settings"
        className="py-3"
      >
        <Text className="flex flex-row items-center gap-2">
          <Icon icon={faGear} />
          Settings
        </Text>
      </MenuItem>
      {onClickClientInformationButton ? (
        <MenuItem
          onClick={onClickClientInformationButton}
          aria-label="Open client information"
          className="py-3"
        >
          <Text className="flex flex-row items-center gap-2">
            <Icon icon={faMemo} />
            Client info
            {hasNewClientInfo && <Tag use={TagUse.Lilac}>New</Tag>}
          </Text>
        </MenuItem>
      ) : (
        <></>
      )}
    </Menu>
  );
}

import { RecordingControl as BaseRecordingControl } from "../../twilio/audio-video-controls/RecordingControl";
import { toast } from "react-hot-toast";
import { useRecordingControl } from "../../twilio/audio-video-controls/useRecordingControl";
import { isRecordingAtom } from "../../twilio";
import { ConsentStatus, DataTrackMessageTypeEnum } from "../../twilio/types";
import { useAtomValue, useSetAtom, useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { ConsentModalWrapper as ConsentModal } from "./ConsentModal";
import { useSendDataTrackMessageCallback } from "../../twilio/messages/useSendDataTrackMessageCallback";
import {
  patientTranscriptionConsentAtom,
  providerTranscriptionConsentAtom,
  showTranscriptionConsentModalAtom,
  transcriptionFeatureEnabledAtom,
} from "./state";
import { RecordingNoticeWrapper as RecordingNotice } from "./RecordingNotice";
import { Tooltip } from "@grow-therapy-team/sprout-ui";
import { useCallback, useEffect, useState } from "react";
import { ConsentDeclinedToast } from "./ConsentDeclinedToast";
import { useAtomCallback } from "jotai/utils";

export function RecordingControl() {
  const [showRecordingNotice, setShowRecordingNotice] = useState(false);
  const [hasShownRecordingNotice, setHasShownRecordingNotice] = useState(false);
  const isTranscriptionFeatureEnabled = useAtomValue(
    transcriptionFeatureEnabledAtom,
  );

  const { providerShortId, patientShortId } = useParams();
  const { sendRecordingStatusUpdate, isRecording, isVisible } =
    useRecordingControl(
      providerShortId,
      patientShortId,
      isTranscriptionFeatureEnabled,
    );
  const setIsRecording = useSetAtom(isRecordingAtom);
  const [isTranscriptionConsentModalOpen, setIsTranscriptionModalOpen] =
    useAtom(showTranscriptionConsentModalAtom);
  const patientConsent = useAtomValue(patientTranscriptionConsentAtom);
  const providerConsent = useAtomValue(providerTranscriptionConsentAtom);
  const getProviderConsent = useAtomCallback(
    useCallback((get) => get(providerTranscriptionConsentAtom), []),
  );

  const toggleRecording = async (shouldRecord: boolean) => {
    setShowRecordingNotice(false);
    sendRecordingStatusUpdate(shouldRecord);
    setIsRecording(shouldRecord);
  };
  const sendMessage = useSendDataTrackMessageCallback();
  const bothPartiesConsented =
    patientConsent === ConsentStatus.OPTED_IN &&
    providerConsent === ConsentStatus.OPTED_IN;

  useEffect(
    function notifyPatientWhenRecordingFirstStarts() {
      if (isRecording && !hasShownRecordingNotice) {
        setShowRecordingNotice(true);
        setHasShownRecordingNotice(true);
      }
    },
    [isRecording, hasShownRecordingNotice],
  );

  const showConsentToast = () => {
    toast.custom(
      (t) => (
        <ConsentDeclinedToast
          onClose={() => toast.remove(t.id)}
          visitorDeclinedConsent
        />
      ),
      {
        position: "bottom-center",
        duration: 30_000,
      },
    );
  };

  const declineConsent = useCallback(() => {
    sendMessage(DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED, {
      clientConsent: ConsentStatus.PENDING,
    });
    setIsTranscriptionModalOpen(false);
    showConsentToast();
  }, [sendMessage, setIsTranscriptionModalOpen]);

  const autoDismissConsent = useCallback(() => {
    sendMessage(DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED, {
      clientConsent: ConsentStatus.PENDING,
    });
    setIsTranscriptionModalOpen(false);
  }, [sendMessage, setIsTranscriptionModalOpen]);

  if (!isVisible) return null;

  return (
    <>
      <ConsentModal
        isOpen={isTranscriptionConsentModalOpen}
        declineConsent={declineConsent}
        autoDismissConsent={autoDismissConsent}
        recordConsent={async () => {
          // Send consent to provider
          sendMessage(
            DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED,
            {
              clientConsent: ConsentStatus.OPTED_IN,
            },
          );
          setIsTranscriptionModalOpen(false);

          // Start recording
          const providerConsent = getProviderConsent();
          if (providerConsent === ConsentStatus.OPTED_IN) {
            await toggleRecording(true);
          }
        }}
      />
      {(bothPartiesConsented || isRecording) && (
        <Tooltip
          isOpen={showRecordingNotice}
          disabled={!showRecordingNotice}
          clickable
          text={
            <RecordingNotice onClose={() => setShowRecordingNotice(false)} />
          }
          className="bg-transparent opacity-100"
        >
          <BaseRecordingControl
            className="rebrand:bg-lilac-700"
            isRecording={isRecording}
            onRecordingToggle={toggleRecording}
            consentPending={false} // Clients don't see this until they've consented
          />
        </Tooltip>
      )}
    </>
  );
}

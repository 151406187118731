import {
  Button,
  ButtonType,
  DrawerBody,
  DrawerFooter,
  Icon,
  IconButton,
  Tag,
  TagUse,
  Text,
  TextInput,
} from "@grow-therapy-team/sprout-ui";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Message } from "@twilio/conversations";
import { ChatBubble } from "./ChatBubble";
import { scrollToBottom } from "../utils";
import { DrawerHeader } from "../DrawerHeader";
import { Toast, ToastVariant } from "..";
import { ParticipantStatus } from "../../types";
import { faPaperPlaneTop } from "@fortawesome/pro-solid-svg-icons";

export type ChatDrawerProps = {
  identity?: string;
  chatName?: string;
  messages: Message[];
  onSend: (message: string) => void;
  quickReplyComponent?: ReactNode;
  connectedAt?: number;
  footerText?: string;
  headerText?: string;
  emptyState?: ReactNode;
  isEmptyState?: boolean;
  chatStatus?: ParticipantStatus;
  onGoToChatList?: () => void;
  sendIsDisabled?: boolean;
  isError?: boolean;
};

function ChatError() {
  return (
    <Toast
      className="bg-transparent rebrand:bg-transparent mb-4"
      data-testid="chat-drawer.error"
      variant={ToastVariant.Neutral}
    >
      <span>
        <Icon className="text-coral-600 mr-4" variant="solid" name="warning" />
        Unable to connect to chat.
      </span>
    </Toast>
  );
}

export function ChatDrawer({
  identity,
  chatName,
  messages,
  onSend,
  quickReplyComponent,
  connectedAt,
  footerText,
  headerText,
  emptyState,
  isEmptyState,
  chatStatus,
  onGoToChatList,
  sendIsDisabled,
  isError,
}: ChatDrawerProps) {
  const [messageDraft, setMessageDraft] = useState<string>("");
  const shouldShowDate = !!connectedAt || messages.length > 0;
  const buttonIsDisabled = !messageDraft.trim() || sendIsDisabled || isError;
  const scrollContainer = useRef<HTMLDivElement>(null);

  useEffect(
    function scrollToBottomOfChatWindow() {
      if (scrollContainer?.current) {
        scrollToBottom(scrollContainer.current);
      }
    },
    [messages, scrollContainer],
  );

  const onSendMessage = () => {
    onSend(messageDraft);
    setMessageDraft("");
  };

  return (
    <>
      <DrawerHeader>
        {onGoToChatList && (
          <button
            className="text-lilac-800 flex items-center"
            onClick={onGoToChatList}
          >
            <Icon name="arrow-left-long" className="mr-2 w-3" />
            <Text variant="xs" className="font-medium">
              ALL CHATS
            </Text>
          </button>
        )}
        <div className="flex items-center w-full">
          <div
            className="fs-exclude"
            data-dd-privacy="mask"
            data-dd-action-name="Click on chat name"
          >
            {chatName || "Chat"}
          </div>
          {chatStatus && (
            <Tag
              className="ml-2"
              use={
                chatStatus === ParticipantStatus.IN_SESSION
                  ? TagUse.Green
                  : TagUse.Neutral
              }
            >
              {chatStatus}
            </Tag>
          )}
        </div>
      </DrawerHeader>
      {headerText && (
        <div className="px-8 py-3">
          <Text
            variant="xs"
            className="text-neutral-600 rebrand:next-neutral-700"
          >
            {headerText}
          </Text>
        </div>
      )}
      {isEmptyState ? (
        <DrawerBody>{emptyState}</DrawerBody>
      ) : (
        <>
          <DrawerBody className="bg-neutral-200">
            <div
              className="flex flex-col h-full overflow-y-auto"
              ref={scrollContainer}
            >
              <div className="pb-6">
                <Text
                  variant="xs"
                  className="text-neutral-500 rebrand:text-neutral-700 text-center"
                >
                  {connectedAt &&
                    `Session started at ${new Date(
                      connectedAt,
                    ).toLocaleTimeString([], {
                      hour: "numeric",
                      minute: "numeric",
                    })}, `}
                  {shouldShowDate &&
                    new Date().toLocaleString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                </Text>
              </div>
              {messages.map((message) => {
                const isByCurrentUser = message.author === identity;
                return (
                  <ChatBubble
                    key={message.sid}
                    message={message}
                    isSelf={isByCurrentUser}
                  />
                );
              })}
              {footerText && (
                <div className="pt-6 mt-auto">
                  <Text
                    variant="xs"
                    className="text-neutral-600 rebrand:text-neutral-700 text-center"
                  >
                    {footerText}
                  </Text>
                </div>
              )}
            </div>
          </DrawerBody>
          {quickReplyComponent && (
            <div className="bg-neutral-100 rebrand:bg-neutral-400 px-4 pb-2">
              {quickReplyComponent}
            </div>
          )}
          <DrawerFooter>
            <div className="flex flex-col w-full">
              {isError && <ChatError />}
              <form
                className="flex w-full"
                onSubmit={(event) => event.preventDefault()}
              >
                <TextInput
                  onChange={(event) => setMessageDraft(event.target.value)}
                  placeholder="Compose your message"
                  className="mr-2 !rebrand:bg-neutral-100"
                  value={messageDraft}
                />
                <Button
                  disabled={buttonIsDisabled}
                  onClick={onSendMessage}
                  type={ButtonType.Submit}
                  className="hidden sm:block"
                >
                  Send
                </Button>
                <IconButton
                  disabled={buttonIsDisabled}
                  onClick={onSendMessage}
                  type={ButtonType.Submit}
                  className="sm:hidden"
                  aria-label="Send message"
                  iconDefinition={faPaperPlaneTop}
                />
              </form>
            </div>
          </DrawerFooter>
        </>
      )}
    </>
  );
}

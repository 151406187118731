import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon, Text } from "@grow-therapy-team/sprout-ui";
import classNames from "classnames";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

interface HideSelfViewButtonProps {
  onClick: () => void;
  isSelfViewHidden: boolean;
  position?: string;
  className?: string;
  isMobile: boolean;
}

export function HideSelfViewButton({
  onClick,
  isSelfViewHidden,
  position,
  className,
  isMobile,
}: HideSelfViewButtonProps) {
  const [showButtonTitle, setShowButtonTitle] = useState(false);
  const instructionsShouldBeOnRight = position?.includes("left");
  const shouldShowButtonTitle =
    showButtonTitle && !isSelfViewHidden && !isMobile;

  return (
    <div
      className={twMerge(
        classNames(
          "absolute z-10 flex items-center max-h-8",
          className,
          position,
          {
            "top-2 right-2": !position,
          },
        ),
      )}
    >
      {isSelfViewHidden && (
        <div
          className={classNames(
            "text-neutral-100 py-2 mx-2 px-3 bg-neutral-900 rounded-lg fade-out-after-5s ",
            {
              "order-2": instructionsShouldBeOnRight,
            },
          )}
          role="alert"
          aria-live="polite"
        >
          <Text variant="sm">Others can still see your video</Text>
        </div>
      )}
      <Button
        onClick={onClick}
        //@ts-ignore FIXME: (CARE-786) Add intrinsic attributes to sprout Button component
        onMouseEnter={() => setShowButtonTitle(true)}
        onMouseLeave={() => setShowButtonTitle(false)}
        className={classNames(
          "focus:opacity-100 opacity-0 px-2 py-0.5 h-7 flex items-center rebrand:shadow-border-none rebrand:bg-neutral-800 rebrand:hover:bg-neutral-800 rebrand:hover:opacity-100 rebrand:active:bg-neutral-800 rebrand:active:text-neutral-300 rebrand:text-neutral-300 rebrand:focus-visible:outline-none rebrand:focus-visible:outline-lilac-700 focus-visible:shadow-border-none rebrand:focus-visible:shadow-border-none",
          {
            "group-hover:opacity-75": !isSelfViewHidden,
            "opacity-75": isSelfViewHidden,
          },
        )}
      >
        {shouldShowButtonTitle && (
          <Text
            variant={"sm"}
            className="mr-1 inline"
            data-testid="hide-self-view-button.shown"
          >
            {"Hide self view"}
          </Text>
        )}
        {isSelfViewHidden && (
          <Text variant={"sm"} className="mr-1 inline opacity-75">
            {"(You)"}
          </Text>
        )}
        <Icon icon={faEyeSlash} aria-hidden className="w-4" />
        <span className="sr-only">
          {isSelfViewHidden ? "Show" : "Hide"} self view
        </span>
      </Button>
    </div>
  );
}

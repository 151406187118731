import {
  Button,
  CalloutBanner,
  CalloutBannerTheme,
} from "@grow-therapy-team/sprout-ui";
import { useCountdown } from "../../../hooks/useCountdown";
import { faDoorOpen } from "@fortawesome/pro-solid-svg-icons";

export function JoinSessionCallout({
  countdown,
  joinCallback,
}: {
  countdown?: string | null;
  joinCallback: () => void;
}) {
  return (
    countdown && (
      <CalloutBanner
        compact
        theme={CalloutBannerTheme.Green}
        icon={faDoorOpen}
        heading="Your provider is ready to see you!"
        headingVariant="md"
        actionGroup={[
          <Button key="joinSession" use="secondary" onClick={joinCallback}>
            Join session now
          </Button>,
        ]}
        data-testid="deadline"
        className="z-20"
      >
        Automatically transferring in {countdown} seconds.
      </CalloutBanner>
    )
  );
}

export function JoinSessionCalloutWrapper({
  deadline,
  joinCallback,
}: {
  deadline?: Date | null;
  joinCallback: () => void;
}) {
  const countdown = useCountdown({
    deadline,
    formatString: "s",
    onFinish: joinCallback,
  });

  return (
    <JoinSessionCallout countdown={countdown} joinCallback={joinCallback} />
  );
}

import {
  ConnectionState as SyncConnectionState,
  SyncMapItem,
} from "twilio-sync";
import {
  LocalAudioTrack,
  LocalDataTrack,
  LocalParticipant,
  LocalTrackPublication,
  LocalVideoTrack,
  NetworkQualityLevel,
  NoiseCancellation,
  NetworkQualityStats,
  RemoteDataTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
  Room,
  Track,
  TwilioError,
} from "twilio-video";
import { Message } from "@twilio/conversations";

// source: twilio-video/tsdef/Room.d.ts
export type TwilioRoomEventListeners = {
  disconnected: (...args: [Room, TwilioError]) => Promise<void>;
  dominantSpeakerChanged: (...args: [RemoteParticipant]) => Promise<void>;
  participantConnected: (...args: [RemoteParticipant]) => Promise<void>;
  participantDisconnected: (...args: [RemoteParticipant]) => Promise<void>;
  participantReconnected: (...args: [RemoteParticipant]) => Promise<void>;
  participantReconnecting: (...args: [RemoteParticipant]) => Promise<void>;
  reconnected: () => Promise<void>;
  reconnecting: (...args: [TwilioError]) => Promise<void>;
  recordingStarted: () => Promise<void>;
  recordingStopped: () => Promise<void>;
  trackDimensionsChanged: (
    ...args: [RemoteVideoTrack, RemoteParticipant]
  ) => Promise<void>;
  trackDisabled: (
    ...args: [RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackEnabled: (
    ...args: [RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackMessage: (
    ...args: [string, RemoteDataTrack, RemoteParticipant]
  ) => Promise<void>;
  trackPublished: (
    ...args: [RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackPublishPriorityChanged: (
    ...args: [Track.Priority, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackStarted: (...args: [RemoteTrack, RemoteParticipant]) => Promise<void>;
  trackSubscribed: (
    ...args: [RemoteTrack, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackSubscriptionFailed: (
    ...args: [TwilioError, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackSwitchedOff: (
    ...args: [RemoteTrack, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackSwitchedOn: (
    ...args: [RemoteTrack, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackUnpublished: (
    ...args: [RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackUnsubscribed: (
    ...args: [RemoteTrack, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackWarning: (
    ...args: [string, LocalTrackPublication, LocalParticipant]
  ) => Promise<void>;
  trackWarningsCleared: (
    ...args: [LocalTrackPublication, LocalParticipant]
  ) => Promise<void>;
};

export type TrackSubscriptionHandler = (
  ...args: Parameters<TwilioRoomEventListeners["trackSubscribed"]>
) => void;
export type ParticipantConnectionEventHandler = (
  ...args: Parameters<TwilioRoomEventListeners["participantConnected"]>
) => void;
export type RoomDisconnectedEventHandler = (
  ...args: Parameters<TwilioRoomEventListeners["disconnected"]>
) => void;
export type RoomReconnectedEventHandler = (
  ...args: Parameters<TwilioRoomEventListeners["reconnected"]>
) => void;
export type RoomReconnectingEventHandler = (
  ...args: Parameters<TwilioRoomEventListeners["reconnecting"]>
) => void;

export type ConnectionError = {
  terminal: boolean;
  message: string;
};

export type SyncMapEvent = "itemAdded" | "itemUpdated" | "itemRemoved";

export type SyncMapEventHandler = (SyncMapItem: { item: SyncMapItem }) => void;

export type TrackEvent =
  | "trackSubscribed"
  | "trackUnsubscribed"
  | "trackSwitchedOff"
  | "trackSwitchedOn"
  | "trackEnabled";

export type LocalAVTrack =
  | LocalVideoTrack
  | (LocalAudioTrack & { noiseCancellation?: NoiseCancellation });

export enum DataTrackMessageTypeEnum {
  RECORDING_STATUS_UPDATED = "RECORDING_STATUS_UPDATED",
  PROVIDER_TRANSCRIPTION_CONSENT_UPDATED = "PROVIDER_TRANSCRIPTION_CONSENT_UPDATED",
  CLIENT_TRANSCRIPTION_CONSENT_UPDATED = "CLIENT_TRANSCRIPTION_CONSENT_UPDATED",
  PARTICIPANT_SUBSCRIBED_TO_DATA_TRACK = "PARTICIPANT_SUBSCRIBED_TO_DATA_TRACK",
}

type RecordingStatusUpdatedDetails = {
  isRecording: boolean;
};

export type RecordingStatusUpdatedMessage = {
  messageType: DataTrackMessageTypeEnum.RECORDING_STATUS_UPDATED;
  details: RecordingStatusUpdatedDetails;
  sender: {
    entityId: string;
    entityType: string;
  };
};

type ProviderTranscriptionConsentUpdatedDetails = {
  providerConsent: ConsentStatus;
  requestClientConsent: boolean;
};

export type ProviderTranscriptionConsentUpdatedMessage = {
  messageType: DataTrackMessageTypeEnum.PROVIDER_TRANSCRIPTION_CONSENT_UPDATED;
  details: ProviderTranscriptionConsentUpdatedDetails;
  sender: {
    entityId: string;
    entityType: string;
  };
};

type ClientTranscriptionConsentUpdatedDetails = {
  clientConsent: ConsentStatus;
};

export type ClientTranscriptionConsentUpdatedMessage = {
  messageType: DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED;
  details: ClientTranscriptionConsentUpdatedDetails;
  sender: {
    entityId: string;
    entityType: string;
  };
};

export type ClientSubscriptionMessage = {
  messageType: DataTrackMessageTypeEnum.PARTICIPANT_SUBSCRIBED_TO_DATA_TRACK;
  sender: {
    entityId: string;
    entityType: string;
  };
};

export type DataTrackMessage =
  | RecordingStatusUpdatedMessage
  | ProviderTranscriptionConsentUpdatedMessage
  | ClientTranscriptionConsentUpdatedMessage
  | ClientSubscriptionMessage;
export type DataTrackMessageDetails =
  | RecordingStatusUpdatedDetails
  | ProviderTranscriptionConsentUpdatedDetails
  | ClientTranscriptionConsentUpdatedDetails;

export type LocalTrack = LocalDataTrack | LocalAVTrack;

export type TrackKind = "audio" | "video" | "data";

export type AudioVideoTrackKind = Extract<TrackKind, "audio" | "video">;

export enum TrackSource {
  Camera = "camera",
  Screen = "screen",
  Microphone = "microphone",
}

export type NetworkQualityChangeHandler = (
  networkQualityLevel: NetworkQualityLevel,
  networkQualityStats: NetworkQualityStats,
) => void;

export type MessageHandler = (message: Message) => void;

export type ConnectionStateStatus = SyncConnectionState;

// Enum for the ConnectionStateStatus i.e. ConnectionState from
// twilio-sync/conversations
export enum ConnectionState {
  CONNECTED = "connected",
  CONNECTING = "connecting",
  DISCONNECTED = "disconnected",
  DISCONNECTING = "disconnecting",
  DENIED = "denied",
  ERROR = "error",
  UNKNOWN = "unknown",
}

export type Constraint =
  | ConstrainDOMString
  | ConstrainBoolean
  | ConstrainDouble
  | ConstrainULong;

// enum for MediaDeviceKind
export enum DeviceType {
  AUDIO_INPUT = "audioinput",
  AUDIO_OUTPUT = "audiooutput",
  VIDEO_INPUT = "videoinput",
}

export enum ConsentStatus {
  PENDING = "PENDING",
  OPTED_OUT = "OPTED_OUT",
  OPTED_IN = "OPTED_IN",
}

import {
  Modal,
  ModalBody,
  Text,
  Button,
  Heading,
} from "@grow-therapy-team/sprout-ui";
import HandHoldingPencil from "../../assets/images/hand-holding-pencil.svg?react";
import { ConsentModalText } from "./constants";
import { useEffect } from "react";
import { minutesToMilliseconds } from "date-fns";
import { useParams } from "react-router-dom";
import { useGetTelehealthSessionInfo } from "../../hooks/useGetTelehealthSessionInfo";
import { useAtomValue } from "jotai";
import { participantCountAtom } from "../../twilio";

export type ConsentModalProps = {
  declineConsent: () => void;
  recordConsent: () => void;
  autoDismissConsent: () => void;
  isOpen: boolean;
  autoDismissTimeout?: number;
  providerName: string;
  isMultiPatientSession: boolean;
};

export function ConsentModal({
  declineConsent,
  recordConsent,
  autoDismissConsent,
  isOpen,
  providerName,
  isMultiPatientSession,
  autoDismissTimeout = minutesToMilliseconds(2),
}: ConsentModalProps) {
  useEffect(
    function dismissAfterTwoMinutes() {
      if (!isOpen) return;
      const timeout = setTimeout(function dismissModal() {
        autoDismissConsent();
      }, autoDismissTimeout);
      return function cleanup() {
        clearTimeout(timeout);
      };
    },
    [isOpen, autoDismissTimeout, autoDismissConsent],
  );
  return (
    <Modal
      isOpen={isOpen}
      className="shadow-none sm:max-w-[31rem] md:max-w-[31rem] sm:top-[25%]"
    >
      <ModalBody className="flex flex-col items-center gap-4 p-8 text-center">
        <HandHoldingPencil />
        <div className="flex flex-col gap-2 items-center">
          <Heading variant="md">{`${providerName} ${ConsentModalText.HEADING}`}</Heading>
        </div>
        <div className="w-full flex flex-col items-center text-center">
          {isMultiPatientSession && (
            <Text variant="sm">
              {ConsentModalText.MULTI_PARTICIPANT_DISCLAIMER}
            </Text>
          )}
          <Text variant="sm">{ConsentModalText.FOOTER}</Text>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 w-full">
          <Button onClick={declineConsent} use="secondary" className="w-full">
            {ConsentModalText.DONT_ENABLE_BUTTON}
          </Button>
          <Button onClick={recordConsent} use="primary" className="w-full m-0">
            {ConsentModalText.ENABLE_BUTTON}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export function ConsentModalWrapper({
  ...props
}: Omit<ConsentModalProps, "providerName" | "isMultiPatientSession">) {
  const participantCount = useAtomValue(participantCountAtom);
  const { providerShortId, patientShortId } = useParams();
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const providerName =
    data?.telehealthSessionInfo?.provider?.name ??
    ConsentModalText.PROVIDER_NAME_FALLBACK;

  return (
    <ConsentModal
      {...props}
      providerName={providerName}
      isMultiPatientSession={participantCount > 1}
    />
  );
}

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@grow-therapy-team/sprout-ui";
import { ComponentProps } from "react";
import { useStartNewClientSessionCallback } from "./useStartNewClientSessionCallback";
import { newSessionConfirmationDataAtom } from "../state";
import { useAtom } from "jotai";
import { TrackingEvents, sendLoggingEvents } from "../../events";

type StartNewSessionConfirmationModalProps = Pick<
  ComponentProps<typeof Modal>,
  "onClose" | "isOpen"
> & {
  onConfirm: () => void;
  loading?: boolean;
  visitorName?: string;
};

export function StartNewSessionConfirmationModal({
  loading,
  onClose,
  onConfirm,
  visitorName,
  ...props
}: StartNewSessionConfirmationModalProps) {
  return (
    <Modal {...{ onClose, ...props }}>
      <ModalHeader>
        <span className="fs-exclude" data-dd-privacy="mask">
          Start a new session with {visitorName}?
        </span>
      </ModalHeader>
      <ModalBody>
        <span className="fs-exclude" data-dd-privacy="mask">
          This will end your current session and create a new session with{" "}
          {visitorName}.
        </span>
      </ModalBody>
      <ModalFooter
        actions={
          <>
            <Button loading={loading} onClick={onConfirm}>
              Yes, start new session
            </Button>
            <Button use="secondary-transparent" onClick={onClose}>
              Cancel
            </Button>
          </>
        }
      />
    </Modal>
  );
}

export function StartNewSessionConfirmationModalWrapper() {
  const [newSessionConfirmationData, setNewSessionConfirmationData] = useAtom(
    newSessionConfirmationDataAtom,
  );
  const [onStartNew, { loading: isCompletingSession }] =
    useStartNewClientSessionCallback();
  const { visitorName, visitorUuid, patientShortId } =
    newSessionConfirmationData ?? {};

  return (
    <StartNewSessionConfirmationModal
      isOpen={!!newSessionConfirmationData}
      loading={isCompletingSession}
      onClose={() => {
        setNewSessionConfirmationData(null);
      }}
      onConfirm={async () => {
        if (!visitorUuid || !visitorName || !patientShortId) return;
        await onStartNew(visitorUuid, visitorName, patientShortId);
        sendLoggingEvents(TrackingEvents.PROVIDER_END_AND_START_NEW_SESSION);
        setNewSessionConfirmationData(null);
      }}
      visitorName={visitorName}
    />
  );
}

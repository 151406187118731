import {
  Button,
  Icon,
  IconButton,
  Tag,
  TagUse,
} from "@grow-therapy-team/sprout-ui";
import {
  numWaitingVisitorsAtom,
  drawerStateAtom,
  DrawerState,
  totalUnreadMessageCountAtom,
} from "../state";
import { useAtom, useAtomValue } from "jotai";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { updateFaviconAndTitle } from "../../updateFaviconAndTitle";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { participantCountAtom } from "../../twilio";
import classNames from "classnames";

function ClientsDrawerIconButton({
  notificationCount,
  onClick,
  isHidden,
}: {
  onClick: () => void;
  notificationCount: number;
  isHidden?: boolean;
}) {
  return (
    <div
      className={classNames("relative sm:hidden", {
        hidden: isHidden,
      })}
    >
      <IconButton
        data-testid="clients-drawer-button-mobile"
        onClick={onClick}
        aria-label="Open clients drawer"
        iconDefinition={faUser}
        className="rebrand:bg-neutral_rebrand-900 rebrand:text-neutral-100 rebrand:hover:bg-[#000000]"
      />
      {!!notificationCount && (
        <Tag
          use={TagUse.Lilac}
          className="absolute -top-1 -right-1 z-10 rounded-3xl"
          onClick={onClick}
        >
          {notificationCount}
        </Tag>
      )}
    </div>
  );
}

export function ClientsDrawerButton({
  numberUnreadMessages,
  numberWaiting,
  onClick,
}: {
  numberUnreadMessages: number;
  numberWaiting: number;
  onClick: () => void;
}) {
  useEffect(
    function showClientNotificationInFavIconAndTitle() {
      if (!numberWaiting) return updateFaviconAndTitle();
      const clientNotificationTitle = `(${numberWaiting}) client${
        numberWaiting > 1 ? "s" : ""
      } waiting`;
      updateFaviconAndTitle(
        "../../assets/favicon-alert.png",
        clientNotificationTitle,
      );
    },
    [numberWaiting],
  );

  return (
    <Button
      data-testid="clients-drawer-button"
      use="secondary"
      className="hidden sm:flex items-center gap-x-2"
      onClick={onClick}
    >
      <Icon variant="solid" className="mr-2" name="user" />
      Clients
      {!!numberWaiting && (
        <Tag className="bg-ivy-200 rebrand:bg-lilac-100 lowercase">
          {numberWaiting} waiting
        </Tag>
      )}
      {!!numberUnreadMessages && (
        <Tag className="bg-ivy-200 rebrand:bg-lilac-100 lowercase">
          <Icon className="mr-1" variant="solid" name="message" />{" "}
          {numberUnreadMessages}
        </Tag>
      )}
    </Button>
  );
}

export function ClientsDrawerButtonWrapper() {
  const [drawerState, setDrawerState] = useAtom(drawerStateAtom);
  const numberOfWaitingClients = useAtomValue(numWaitingVisitorsAtom);
  const numberOfUnreadMessages = useAtomValue(totalUnreadMessageCountAtom);
  const isInSession = !!useAtomValue(participantCountAtom);

  const onClick = (): void => {
    if (drawerState === DrawerState.CLIENTS) {
      setDrawerState(null);
      return;
    }
    toast.remove();
    setDrawerState(DrawerState.CLIENTS);
  };

  return (
    <>
      <ClientsDrawerIconButton
        notificationCount={numberOfWaitingClients}
        onClick={onClick}
        isHidden={isInSession}
      />
      <ClientsDrawerButton
        numberUnreadMessages={numberOfUnreadMessages}
        numberWaiting={numberOfWaitingClients}
        onClick={onClick}
      />
    </>
  );
}

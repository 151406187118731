import { RecordingControl as BaseRecordingControl } from "../../twilio/audio-video-controls/RecordingControl";
import { useRecordAudioCallbacks } from "./useRecordAudioCallbacks";
import { useCombineAudioTrackMediaStreams } from "./useCombineAudioTracks";
import { useRecordingControl } from "../../twilio/audio-video-controls/useRecordingControl";
import { useGetProviderPatientConsent } from "./useGetProviderPatientConsent";
import { ConsentStatus, DataTrackMessageTypeEnum } from "../../twilio/types";
import { useEffect, useState } from "react";
import { ConsentModal } from "./ConsentModal";
import { useAtom, useAtomValue } from "jotai";
import { inSessionPatientInformationAtom, providerShortIdAtom } from "../state";
import { useRecordProviderTranscriptConsentMutation } from "./useRecordProviderTranscriptionConsentMutation";
import { useSendDataTrackMessageCallback } from "../../twilio/messages/useSendDataTrackMessageCallback";
import { requestingClientConsentAtom } from "./state";
import { useRequestingClientConsentToast } from "./useRequestingClientConsentToast";
import classNames from "classnames";
import { useSendClientTranscriptionConsents } from "./useSendClientTranscriptionConsents";
import { useGate } from "statsig-react";
import { telehealthTranscriptionGate } from "../../statsig/gates";

/**
 * TODO: Extract contents to parent once feature is launched
 */
function CombineAudioTrackMediaStreams() {
  useCombineAudioTrackMediaStreams();

  return null;
}

export function RecordingControl() {
  const { value: telehealthTranscriptionIsEnabled } = useGate(
    telehealthTranscriptionGate,
  );
  useRequestingClientConsentToast();
  const { patientShortId } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};
  const providerShortId = useAtomValue(providerShortIdAtom);
  const [requestingClientConsent, setRequestingClientConsent] = useAtom(
    requestingClientConsentAtom,
  );

  const sendMessage = useSendDataTrackMessageCallback();
  const { startRecording, stopRecording } = useRecordAudioCallbacks();
  const { isRecording, isVisible, sendRecordingStatusUpdate } =
    useRecordingControl(
      providerShortId,
      patientShortId,
      telehealthTranscriptionIsEnabled,
    );
  const sendConsentStatuses = useSendClientTranscriptionConsents(
    Boolean(isVisible),
  );
  const recordProviderConsent = useRecordProviderTranscriptConsentMutation(
    providerShortId,
    patientShortId,
  );
  const { providerConsent, patientConsent } = useGetProviderPatientConsent(
    providerShortId,
    patientShortId,
  );

  const [showConsentModal, setShowConsentModal] = useState(false);

  useEffect(() => {
    sendConsentStatuses();
  }, [sendConsentStatuses]);

  const toggleRecording = async (shouldRecord: boolean) => {
    sendRecordingStatusUpdate(shouldRecord);
    sendConsentStatuses();
    if (shouldRecord) {
      startRecording();
    } else {
      await stopRecording();
    }
  };

  const requestClientConsent = () => {
    sendMessage(
      DataTrackMessageTypeEnum.PROVIDER_TRANSCRIPTION_CONSENT_UPDATED,
      {
        providerConsent: ConsentStatus.OPTED_IN,
        requestClientConsent: true,
      },
    );
    setRequestingClientConsent(true);
  };

  const recordOptIn = async () => {
    const { data } = await recordProviderConsent(true);
    const providerIsOptedIn =
      data?.recordProviderTranscriptionConsent?.isOptedIn;
    if (providerIsOptedIn && patientConsent !== ConsentStatus.OPTED_IN) {
      requestClientConsent();
    } else if (providerIsOptedIn && patientConsent === ConsentStatus.OPTED_IN) {
      toggleRecording(true);
    }
    setShowConsentModal(false);
    sendConsentStatuses();
  };

  const bothPartiesConsented =
    patientConsent === ConsentStatus.OPTED_IN &&
    providerConsent === ConsentStatus.OPTED_IN;

  if (!isVisible || providerConsent === ConsentStatus.OPTED_OUT) return null;

  return (
    <>
      {showConsentModal && (
        <ConsentModal
          onClose={() => setShowConsentModal(false)}
          recordOptIn={recordOptIn}
          isOpen={showConsentModal}
        />
      )}
      <CombineAudioTrackMediaStreams />
      <BaseRecordingControl
        className={classNames({
          "rebrand:bg-lilac-700":
            requestingClientConsent || bothPartiesConsented,
        })}
        isRecording={isRecording}
        consentPending={!bothPartiesConsented}
        onRecordingToggle={toggleRecording}
        onPendingConsentClick={() => {
          if (providerConsent !== ConsentStatus.OPTED_IN) {
            setShowConsentModal(true);
          } else {
            requestClientConsent();
          }
        }}
      />
    </>
  );
}

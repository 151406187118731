import {
  PhotoAvatar,
  Skeleton,
  Text,
  Tag,
  TagUse,
} from "@grow-therapy-team/sprout-ui";
import { Provider } from "../../types";
import { useGetTelehealthSessionInfo } from "../../hooks";
import { useParams } from "react-router-dom";
import { useAtomValue } from "jotai";
import { providerIsOnlineAtom } from "../state";
import { twMerge } from "tailwind-merge";

interface WrapperPassThroughProps {
  className?: string;
  label?: string;
  hideStatus?: boolean;
}
export interface ProviderInfoSectionProps extends WrapperPassThroughProps {
  provider?: Pick<Provider, "image" | "name" | "pronouns">;
  loading?: boolean;
  providerIsOnline: boolean | null;
}

function ProviderStatusTag({
  className,
  providerIsOnline,
}: {
  className?: string;
  providerIsOnline: ProviderInfoSectionProps["providerIsOnline"];
}) {
  if (providerIsOnline === null) return null;

  const tagColor = providerIsOnline ? TagUse.Green : TagUse.Neutral;
  const tagLabel = providerIsOnline ? "Available" : "Offline";

  return (
    <Tag className={className} use={tagColor}>
      {tagLabel}
    </Tag>
  );
}

export function ProviderInfoSection({
  className,
  label = "Waiting room of",
  provider,
  loading,
  providerIsOnline,
  hideStatus = false,
}: ProviderInfoSectionProps) {
  return (
    <div
      data-testid="provider-info-section"
      className={twMerge("relative inset-0 ml-3 self-start p-4", className)}
    >
      {loading ? (
        <div className="flex items-center">
          <span data-testid="provider-info-section.image-skeleton">
            <Skeleton className="my-0 mx-0 rounded-full w-12 h-12 sm:w-18 sm:h-18" />
          </span>
          <div className="flex flex-col justify-center ml-2">
            <span data-testid="provider-info-section.waiting-room-skeleton">
              <Skeleton className="my-0 mx-0 h-5 w-28" />
            </span>
            <span data-testid="provider-info-section.name-skeleton">
              <Skeleton className="my-0 mx-0 w-60 mt-2" />
            </span>
            {!hideStatus && (
              <span data-testid="provider-info-section.status-skeleton">
                <Skeleton className="my-0 mx-0 h-6 w-20 mt-2 rounded-full" />
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center">
          <div
            className="sm:hidden fs-exclude"
            data-dd-privacy="mask"
            data-testid="provider-info-section.image"
          >
            <PhotoAvatar
              alt={`Provider ${provider?.name ?? "picture"}`}
              src={provider?.image ?? ""}
              size="sm"
            />
          </div>
          <div
            className="hidden sm:block fs-exclude"
            data-dd-privacy="mask"
            data-testid="provider-info-section.image"
          >
            <PhotoAvatar
              alt={`Provider ${provider?.name ?? "picture"}`}
              src={provider?.image ?? ""}
              size="md"
            />
          </div>
          <div className="flex flex-col justify-center ml-2">
            <Text
              variant="sm"
              data-testid="provider-info-section.provider-waiting-room"
            >
              {label}
            </Text>

            <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-center">
              <Text
                data-testid="provider-info-section.provider-name"
                variant="lg"
                className="text-ivy-500 font-medium text-xl leading-8 fs-exclude"
                data-dd-privacy="mask"
              >
                {provider?.name}
                {(provider?.pronouns?.length ?? 0) > 0 && (
                  <span
                    className="font-normal"
                    data-testid="provider-info-section.provider-pronouns"
                  >
                    {" "}
                    ({provider?.pronouns![0]})
                  </span>
                )}
              </Text>

              {!hideStatus && (
                <ProviderStatusTag providerIsOnline={providerIsOnline} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function ProviderInfoSectionWrapper(props: WrapperPassThroughProps) {
  const { providerShortId, patientShortId } = useParams();
  const { data, loading } = useGetTelehealthSessionInfo(
    providerShortId,
    patientShortId,
  );
  const providerIsOnline = useAtomValue(providerIsOnlineAtom);
  const provider = data?.telehealthSessionInfo?.provider;

  return (
    <ProviderInfoSection
      {...props}
      loading={loading}
      provider={provider}
      providerIsOnline={providerIsOnline}
    />
  );
}

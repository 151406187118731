import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  RadioGroupLayout,
} from "@grow-therapy-team/sprout-ui";
import {
  CANCEL_MODAL_TITLE,
  EDIT_MODAL_TITLE,
  RECURRING_UPDATE_OPTIONS,
  UpdateMode,
} from "./constants";
import { useState } from "react";
import { getAppointmentCancelLink } from "./utils";
import { useAtomValue } from "jotai";
import { scheduledPatientInformationAtom } from "../schedule-preview/state";
import { ScheduledPatientInformation } from "../schedule-preview/types";
import { FetchResult } from "@apollo/client";
import { RescheduleAppointmentMutationData } from "../useRescheduleAppointmentMutation";
import { UpdateRecurringAppointmentMutationData } from "../useRescheduleRecurringAppointmentMutation";

export type RecurringAppointmentModalState = {
  isOpen: boolean;
  isCancel: boolean;
};

type RecurringAppointmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  scheduledPatientInformation: ScheduledPatientInformation;
  isCancel: boolean;
  onRescheduleSingle: () => Promise<void | FetchResult<RescheduleAppointmentMutationData>>;
  onRescheduleRecurring: () => Promise<void | FetchResult<UpdateRecurringAppointmentMutationData>>;
  onCancel?: () => void;
};

export function RecurringAppointmentModal({
  isOpen,
  onClose,
  scheduledPatientInformation,
  isCancel,
  onRescheduleSingle,
  onRescheduleRecurring,
  onCancel,
}: RecurringAppointmentModalProps) {
  const { recurringAppointmentGrouping, appointmentShortId } =
    scheduledPatientInformation;
  const [updateMode, setUpdateMode] = useState<UpdateMode>(UpdateMode.SINGLE);
  const isRecurring = updateMode === UpdateMode.RECURRING;
  const nextLink = getAppointmentCancelLink({
    appointmentShortId: appointmentShortId,
    gid: isRecurring
      ? recurringAppointmentGrouping?.recurringAppointmentGroupingId
      : undefined,
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
        {isCancel ? CANCEL_MODAL_TITLE : EDIT_MODAL_TITLE}
      </ModalHeader>
      <ModalBody>
        <RadioGroup
          layout={RadioGroupLayout.Column}
          name="recurring"
          value={updateMode}
          onChange={({ target: { value } }): void =>
            setUpdateMode(value as UpdateMode)
          }
        >
          <Radio
            label={RECURRING_UPDATE_OPTIONS[UpdateMode.SINGLE]}
            value={UpdateMode.SINGLE}
          />
          <Radio
            label={RECURRING_UPDATE_OPTIONS[UpdateMode.RECURRING]}
            value={UpdateMode.RECURRING}
          />
        </RadioGroup>
      </ModalBody>
      <ModalFooter
        actions={
          isCancel ? (
            <Link
              external
              href={nextLink}
              buttonUse="primary"
              onClick={() => {
                onClose();
                onCancel?.();
              }}
            >
              Next
            </Link>
          ) : (
            <Button
              onClick={() => {
                isRecurring ? onRescheduleRecurring() : onRescheduleSingle();
                onClose();
              }}
            >
              Save
            </Button>
          )
        }
      />
    </Modal>
  );
}

export function RecurringAppointmentModalWrapper({
  recurringAppointmentModalState,
  setRecurringAppointmentModalState,
  onRescheduleSingle,
  onRescheduleRecurring,
  onCancel,
}: Pick<
  RecurringAppointmentModalProps,
  "onRescheduleSingle" | "onRescheduleRecurring" | "onCancel"
> & {
  recurringAppointmentModalState: RecurringAppointmentModalState;
  setRecurringAppointmentModalState: (
    state: RecurringAppointmentModalState,
  ) => void;
}) {
  const scheduledPatientInformation = useAtomValue(
    scheduledPatientInformationAtom,
  );

  if (!scheduledPatientInformation) return;

  return (
    <RecurringAppointmentModal
      isOpen={recurringAppointmentModalState.isOpen}
      onClose={() =>
        setRecurringAppointmentModalState({ isOpen: false, isCancel: false })
      }
      scheduledPatientInformation={scheduledPatientInformation}
      isCancel={recurringAppointmentModalState.isCancel}
      onRescheduleSingle={onRescheduleSingle}
      onRescheduleRecurring={onRescheduleRecurring}
      onCancel={onCancel}
    />
  );
}

import { DataTrackMessageTypeEnum } from "../../twilio/types";
import { useSendDataTrackMessageCallback } from "../../twilio/messages/useSendDataTrackMessageCallback";
import { optedInToConsentStatus } from "./useGetProviderPatientConsent";
import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import {
  inSessionPatientInformationAtom,
  providerPatientConsentAtom,
  providerShortIdAtom,
} from "../state";

export function useSendClientTranscriptionConsents(featureEnabled?: boolean) {
  const getShortIds = useAtomCallback(
    useCallback(
      (get) => ({
        providerShortId: get(providerShortIdAtom),
        patientShortId: get(inSessionPatientInformationAtom)?.patientShortId,
      }),
      [],
    ),
  );

  const getProviderPatientConsent = useAtomCallback(
    useCallback((get) => get(providerPatientConsentAtom), []),
  );
  const sendMessage = useSendDataTrackMessageCallback();

  const sendConsentStatuses = useCallback(() => {
    if (!featureEnabled) {
      return;
    }
    const { providerShortId, patientShortId } = getShortIds();
    if (!providerShortId || !patientShortId) return;

    const { providerConsent, clientConsent } = getProviderPatientConsent();
    sendMessage(DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED, {
      clientConsent: optedInToConsentStatus(clientConsent),
    });
    sendMessage(
      DataTrackMessageTypeEnum.PROVIDER_TRANSCRIPTION_CONSENT_UPDATED,
      {
        providerConsent: optedInToConsentStatus(providerConsent),
        requestClientConsent: false,
      },
    );
  }, [featureEnabled, getProviderPatientConsent, sendMessage, getShortIds]);

  return sendConsentStatuses;
}

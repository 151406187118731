import { faPauseCircle } from "@fortawesome/pro-solid-svg-icons";
import {
  Alert,
  AlertUse,
  Button,
  ButtonSize,
  Card,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { HelpfulTipsDrawerStates } from "../state";
import { DrawerButtonMenu } from "./DrawerButtonMenu";

export interface PreSessionPreparationProps {
  name: string;
  toggleHelpfulTipsDrawer: (drawerState: HelpfulTipsDrawerStates) => void;
  setIsHelpModalOpen: (isOpen: boolean) => void;
  showTabSwitchWarning?: boolean;
}

export function PreSessionPreparation({
  name,
  toggleHelpfulTipsDrawer,
  setIsHelpModalOpen,
  showTabSwitchWarning = false,
}: PreSessionPreparationProps) {
  return (
    <Card
      className="flex flex-col gap-y-6 sm:gap-y-8 w-[35.5rem] p-6 sm:p-8 max-w-[calc(100vw-1.5rem)] mobile:border-x rounded-xl"
      data-testid="pre-session-preparation"
    >
      <div>
        <Text
          data-testid="session-info-section.greeting"
          data-dd-privacy="mask"
          data-dd-action-name="Click on session info"
          as="h1"
          variant="lg"
          className="text-center font-medium fs-exclude hidden sm:block pb-3"
        >
          Hi {name}!
        </Text>

        <Text
          as="h2"
          variant="xl"
          className="text-center font-medium sm:text-ivy-500 sm:text-2xlarge"
        >
          You&apos;re in the right place.
        </Text>

        <Text className="text-center text-neutral-700 max-w-sm pt-2 sm:pt-5 sm:pb-5 m-auto">
          Your provider should be arriving shortly. They have a 10-min grace
          period.
        </Text>
      </div>

      {showTabSwitchWarning && (
        <div data-testid="tab-switch-warning-callout">
          <Alert
            use={AlertUse.Warning}
            title="Please stay here while you wait."
            icon={faPauseCircle}
          >
            <Text variant="sm" className="text-neutral-900 pt-1">
              Switching tabs or apps will remove you from the waiting room and
              will require you to join the session again.
            </Text>
          </Alert>
        </div>
      )}

      <DrawerButtonMenu toggleHelpfulTipsDrawer={toggleHelpfulTipsDrawer} />

      <div className="flex justify-center">
        <Button
          onClick={() => {
            sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
            sendLoggingEvents(TrackingEvents.CLIENT_HELP_REQUEST);
            setIsHelpModalOpen(true);
          }}
          use="link"
          size={ButtonSize.Default}
          className="pt-2"
        >
          Need help?
        </Button>
      </div>
    </Card>
  );
}

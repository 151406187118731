import { differenceInMinutes, parseISO } from "date-fns";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { JoinSessionCalloutWrapper as JoinSessionCallout } from "./delayed-entry/JoinSessionCallout";
import { useCallback, useEffect, useRef } from "react";
import { Statsig, useLayer } from "statsig-react";
import { TelehealthSessionInfoData_TelehealthSessionInfo_Appointment } from "../../hooks";
import { ExperimentLayers } from "../../statsig/experiments";
import { delayedEntryGate } from "../../statsig/gates";
import { recordedMoodCheckin } from "../components/forms-drawer/mood-check-in/useMoodCheckinSelectionsState";
import { visitorMeasuresAtom, visitorNameAtom } from "../state";
import {
  deadlineAtom,
  sessionConversationSidAtom,
} from "./delayed-entry/state";
import {
  PreSessionPreparation,
  PreSessionPreparationProps,
} from "./PreSessionPreparation";
import {
  PreSessionPromptWrapper as PreSessionPrompt,
  PreSessionPromptVariant,
} from "./PreSessionPrompt";
import { useTransitionToMeetingRoom } from "./useWatchForTwilioRoomToken";
import { usePreSessionPromptState } from "./usePreSessionPromptState";
import { useShouldUseMICInfra } from "../../hooks/measures/useShouldUseMICInfra";
import { MICTypes } from "@grow-therapy-team/sprout-ui";

const PRE_SESSION_PROMPT_THRESHOLD_MINUTES = 4;

type SessionInfoSectionWrapperProps = Omit<
  PreSessionPreparationProps,
  "name" | "shouldShowCLientMeasures"
> & {
  appointment?: TelehealthSessionInfoData_TelehealthSessionInfo_Appointment;
  formsAreLoading?: boolean;
  hasForms?: boolean;
};

function isWithinThreshold(appointmentStartTime: string) {
  return (
    differenceInMinutes(parseISO(appointmentStartTime), new Date()) >
    PRE_SESSION_PROMPT_THRESHOLD_MINUTES
  );
}

function appointmentHasStarted(appointmentStartTime?: string) {
  if (!appointmentStartTime) return false;
  return Date.now() > new Date(appointmentStartTime).getTime();
}
export function SessionInfoSectionWrapper({
  appointment,
  hasForms,
  ...props
}: SessionInfoSectionWrapperProps) {
  const { preSessionPrompt, setPreSessionPrompt, clearPreSessionPrompt } =
    usePreSessionPromptState();
  const shouldUseMICInfra = useShouldUseMICInfra();
  const hasClearedLocalStorageRef = useRef(false); // to prevent re-computation
  const [layerName, layerConfig] = ExperimentLayers.Measures;
  const { isLoading, layer } = useLayer(layerName);
  const name = useAtomValue(visitorNameAtom);
  const deadline = useAtomValue(deadlineAtom);
  const hasMood = !!useAtomValue(recordedMoodCheckin);
  const visitorMeasuresAreAllScreeners = useAtomValue(
    visitorMeasuresAtom,
  ).reduce(
    (prev, currMeasure) =>
      prev &&
      !!currMeasure &&
      (currMeasure.type === MICTypes.MeasureSchemaType.GAD7_WITH_SCREENER ||
        currMeasure.type === MICTypes.MeasureSchemaType.PHQ9_WITH_SCREENER),
    shouldUseMICInfra,
  );
  const transitionToMeetingRoom = useTransitionToMeetingRoom();
  const getSessionConversationSid = useAtomCallback(
    useCallback((get) => get(sessionConversationSidAtom), []),
  );
  const joinCallback = useCallback(() => {
    transitionToMeetingRoom(getSessionConversationSid(), {
      onDone() {
        hasClearedLocalStorageRef.current = true;
        clearPreSessionPrompt();
      },
    });
  }, [
    clearPreSessionPrompt,
    getSessionConversationSid,
    transitionToMeetingRoom,
  ]);

  useEffect(
    function allowPreSessionPromptToRecompute() {
      if (!hasMood) return;

      clearPreSessionPrompt();
    },
    [clearPreSessionPrompt, hasMood],
  );

  useEffect(() => {
    if (hasClearedLocalStorageRef.current) return;

    const shouldShowScreener =
      !appointmentHasStarted(appointment?.timeStart) &&
      visitorMeasuresAreAllScreeners;

    if (preSessionPrompt !== undefined) {
      // once set, always set
      return;
    } else if (!appointment?.timeStart) {
      setPreSessionPrompt(PreSessionPromptVariant.TIPS);
    } else if (
      (isWithinThreshold(appointment?.timeStart) || shouldShowScreener) &&
      hasForms
    ) {
      setPreSessionPrompt(PreSessionPromptVariant.CLIENT_MEASURES);
    } else if (!hasMood) {
      // skip if already submitted
      setPreSessionPrompt(PreSessionPromptVariant.MOOD_SURVEY);
    } else {
      setPreSessionPrompt(PreSessionPromptVariant.TIPS);
    }
  }, [
    appointment?.timeStart,
    hasForms,
    hasMood,
    preSessionPrompt,
    setPreSessionPrompt,
    visitorMeasuresAreAllScreeners,
  ]);

  if (!isLoading && preSessionPrompt !== undefined) {
    // what should we do about isLoading?
    // layer.get sends a signal to statsig that the experiment is being applied.
    // it is important that any conditional logic be done before we call
    // layer.get or the experiment results will be skewed/muted.

    if (layer.get<boolean>(layerConfig.ShouldShowClientMeasures, false)) {
      return (
        <div className="flex flex-col items-center gap-y-2 p-4">
          {Statsig.checkGate(delayedEntryGate) && deadline && (
            <JoinSessionCallout
              deadline={deadline}
              joinCallback={joinCallback}
            />
          )}
          <PreSessionPrompt variant={preSessionPrompt} {...props} />
        </div>
      );
    }
  }

  // TODO can be removed once experiment(s) are launched
  return <PreSessionPreparation name={name} {...props} />;
}

import { RemoteParticipant } from "twilio-video";
import { useSetAtom } from "jotai";
import {
  TrackSubscriptionHandler,
  DataTrackMessage,
  DataTrackMessageTypeEnum,
} from "./types";
import {
  remoteTracksByParticipantIdAtom,
  addTrackToParticipant,
  removeTrackFromParticipant,
  trackToDiagnosticInfo,
  remoteParticipantToDiagnosticInfo,
  gracefullyHandleTrackEvents,
} from ".";
import { gracefullyParseJson } from "../utils";
import { useMemo } from "react";
export type UseRemoteDataProps = {
  remoteParticipant: RemoteParticipant;
  onTrackSubscribed?: TrackSubscriptionHandler;
  onTrackUnsubscribed?: TrackSubscriptionHandler;
  onMessageReceived?: (message: DataTrackMessage) => void;
};
import { TrackingEvents, sendLoggingEvents } from "../events";
import { Mutex } from "async-mutex";
import { useRemoteTrackEventListeners } from "./useRemoteTrackEventListeners";
import { logger } from "../datadog/logger";
import { useSendDataTrackMessageCallback } from "./messages/useSendDataTrackMessageCallback";

const dataTrackEventMutex = new Mutex();

function useGetRemoteDataTrackEventListeners({
  remoteParticipant,
  onTrackSubscribed,
  onTrackUnsubscribed,
  onMessageReceived,
}: UseRemoteDataProps) {
  const setRemoteTracks = useSetAtom(remoteTracksByParticipantIdAtom);
  const sendMessage = useSendDataTrackMessageCallback();
  return useMemo(
    () => {
      if (!remoteParticipant) return;
      const parseAndHandleReceivedMessage = (message: string) => {
        const parsedMessage = gracefullyParseJson<DataTrackMessage>(message);
        if (!parsedMessage) {
          logger.error("Unable to parse received message", {
            message,
          });
          return;
        }
        sendLoggingEvents(
          TrackingEvents.REMOTE_DATA_TRACK_MESSAGE_RECEIVED,
          { messageType: parsedMessage?.messageType },
          {
            logLevel: "info",
            message: "Received a message on the data track",
          },
        );
        onMessageReceived?.(parsedMessage);
      };
      return gracefullyHandleTrackEvents(dataTrackEventMutex, {
        trackSubscribed: (track, ...args) => {
          if (track.kind !== "data") return;
          onTrackSubscribed?.(track, ...args);
          setRemoteTracks((prev) =>
            addTrackToParticipant(prev, remoteParticipant.identity, track),
          );

          track.on("message", parseAndHandleReceivedMessage);
          sendMessage(
            DataTrackMessageTypeEnum.PARTICIPANT_SUBSCRIBED_TO_DATA_TRACK,
          );
          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_SUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant data track subscribed",
            },
          );
        },

        trackUnsubscribed: (track, ...args) => {
          if (track.kind !== "data") return;

          // On unsubscribe, the message event listener will not trigger anymore
          // regardless of the presence of the below line; this is just an extra
          // precaution to prevent memory leaks
          track.off("message", parseAndHandleReceivedMessage);
          onTrackUnsubscribed?.(track, ...args);

          setRemoteTracks((prev) =>
            removeTrackFromParticipant(prev, remoteParticipant.identity, track),
          );

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_UNSUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant data track unsubscribed",
            },
          );
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onMessageReceived,
      onTrackSubscribed,
      onTrackUnsubscribed,
      remoteParticipant,
    ],
  );
}

export function useRemoteData({
  remoteParticipant,
  ...otherProps
}: UseRemoteDataProps) {
  const remoteTrackEventListeners = useGetRemoteDataTrackEventListeners({
    remoteParticipant,
    ...otherProps,
  });

  useRemoteTrackEventListeners({
    trackKind: "data",
    remoteParticipant,
    remoteTrackEventListeners,
  });
}
